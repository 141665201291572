
import { Vue, Component } from 'vue-property-decorator'
import { MonthReportDetail } from '@/types/operationMonthReport'

@Component
export default class MonthReport extends Vue {
  private searchInfo = {
    projectName: '',
    yearMonth: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private loading = false
  private tableData: MonthReportDetail[] = []

  created () {
    this.getData()
  }

  // 查询
  searchData () {
    this.page = 1
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<MonthReportDetail> }>(this.$apis.operationmanage.selectOperatingReportByPage, {
      page: this.page,
      size: this.size,
      ...this.searchInfo
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 导出
  onExport () {
    const loading = this.$loading({
      lock: true,
      text: '导出中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.operationmanage.exportOperatingList, this.searchInfo).finally(() => {
      loading.close()
    })
  }

  onDetail (row: MonthReportDetail) {
    this.$router.push({
      name: 'operationMonthReportDetail',
      params: { projectId: row.projectId, yearMonth: row.yearMonth }
    })
  }
}
